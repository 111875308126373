import css from './index.css.yml'
import approaches from './approaches.yml'
import { analytics } from '@/mixins/analytics'

export default {
  name: 'approach',

  mixins: [analytics],

  data() {
    return {
      css: css,
      approaches: approaches,
    }
  },
}
